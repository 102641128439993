#footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #333;
}

.overlay {
  display: none;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(18, 18, 18, 0.558);
}

.overlay.active {
  display: block;
}

.modal-popup {
  display: none;
  position: fixed;
  z-index: 1000;
  background: var(--modal-background-color, #fff);
}

.modal-popup.active {
  display: block;
}

.modal-mailresponse {
  border-radius: 25px;
  max-width: 510px;
  height: 310px;
  width: 100%;
  top: 17%;
  margin: 0 16px;
  padding: 7px;
}

.modal-popup-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-mailresponse-text {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #333;
}

.footer-logo {
  max-width: 10rem;
  max-height: 40px;
}

.footer-contact {
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: space-around;
}

.footer-container__text {
  display: flex;
  justify-content: flex-start;
  color: var(--font-white);
}

.footer-container {
  display: flex;
  width: 100%;
  padding: 30px 16px;

  & p {
    font-weight: 400;
    font-size: 14px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--font-white);
  }

  & b {
    font-weight: 600;
    font-size: 14px;
  }

  &__contatos {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 5px;
    justify-content: space-between;
  }
}

.footer-link {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 150px;
  color: var(--font-white);

  & > a {
    width: 100%;
    text-align: end;
  }

  &__respansive {
    display: none;
  }
}

.footer-copyright {
  padding: 10px 0;
  width: 100%;

  & p {
    font-size: 14px;
    color: var(--font-white);
    padding: 1rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  &::before {
    display: flex;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #262626;
  }
}

.footer-contact__social-media {
  display: flex;
  // width: 132px;
  gap: 2rem;
}

@media screen and (max-width: 800px) {
  .footer-link {
    display: none;

    &__respansive {
      display: flex;
      flex-direction: column;
      padding-top: 32px;
      gap: 24px;
      color: var(--font-white);
    }

    & > a {
      width: 100%;
      text-align: start;
    }
  }

  .flex {
    flex-direction: column;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
  }

  .footer-contact {
    flex-direction: column;
    align-items: center;
  }
}
