#info {
  padding: 8rem 12px 12rem 12px;
  background: linear-gradient(141.3deg, #000000 -32.85%, rgba(0, 0, 0, 0.513514) 20.24%, rgba(0, 0, 0, 0) 54.37%);
}

.info {
  &__title {
    color: #fff;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    font-size: 2.5rem;
  }

  &__box {
    border-radius: 20px;
    border: 2px solid #d9ad00;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
    width: 319px;
    height: 279px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    background: #003a72;
    transition: 0.2s;

    &__img {
      width: 5rem;
      height: 5rem;
    }
    &__container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 80px;
    }
    &__text {
      width: 90%;
      text-align: center;
      color: #fff;
    }
  }

  &__box:hover {
    border-color: #fff;
    transform: scale(1.2);
    box-shadow: 0px 0px 80px #ffffff99;
  }
  &__cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5rem;
    align-items: center;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 5rem;
      align-items: center;
    }
  }
  &__project {
    color: #fff;
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      & h2 {
        font-size: 2rem;
      }
    }
  }
  &__images {
    width: 5rem;
    height: 5rem;
  }
  &__text {
    width: 90%;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

// Para o caso de a tela estar curta a o width ainda grande
@media only screen and (max-height: 700px) {
  #info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text {
    display: flex;
    justify-content: center;
  }

  .info {
    &__images {
      width: 4rem;
      height: 4rem;
    }
    &__project {
      color: #fff;
      font-size: 17px;
      &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    &__cards {
      gap: 1rem;
    }
    &__text {
      width: 10rem;
      color: #fff;
      text-align: center;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .info__project__container h2 {
    font-size: 1.5rem;
  }
  .info__text {
    font-size: 0.8rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
  }

  .info-img {
    width: 20rem;
    height: 20rem;
  }

  .info__images.building {
    margin-left: 1rem;
  }
}
@media only screen and (max-width: 1400px) {
  .info-container {
    min-height: 60vh;
    gap: 10px;
    width: 90%;
  }
  #mobile {
    height: 90vh;
  }

  #mobile__title {
    color: #fff;
    text-align: center;
    padding: 40px;
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1023px) {
  #info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info {
    &__title {
      font-size: 2.3rem;
    }

    &__box {
      width: 300px;
      height: 250px;
    }

    &__images {
      width: 7rem;
      height: 7rem;
    }
    &__project {
      color: #fff;
      font-size: 17px;
      &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    &__cards {
      gap: 1rem;
    }
    &__text {
      width: 13rem;
      color: #fff;
      text-align: center;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .info__project__container h2 {
    font-size: 2rem;
  }
  .info__text {
    font-size: 1rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
  }

  .info-img {
    width: 12rem;
    height: 12rem;
  }

  .info__images.building {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 950px) {
  .info {
    &__box {
      width: 230px;
      height: 220px;
      &__container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
      }

      &__text {
        font-size: 1.25rem;
      }
    }

    &__images {
      width: 6rem;
      height: 6rem;
    }
    &__project {
      color: #fff;
      font-size: 17px;
      &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    &__cards {
      gap: 1rem;

      &__container {
        flex-direction: column;
      }
    }
    &__text {
      width: 13rem;
      color: #fff;
      text-align: center;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .info__project__container h2 {
    font-size: 1.5rem;
  }
  .info__text {
    font-size: 1rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
  }

  .info-img {
    width: 10rem;
    height: 10rem;
  }

  .info__images.building {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 767px) { 
  #info {
    padding: 4rem 12px 5rem 12px;
  }

  .info {
    &__title {
      font-size: 1.325rem;
      
    }
  }
}