#success-method {
  background: url(../assets/images/success-method-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 90vh;
  /* background-position: 0; */
}

@media only screen and (max-height: 700px) {
  #success-method {
    height: 35rem;
  }
}

@media only screen and (max-width: 1023px) {
  #success-method {
    height: 35rem;
    background-size: 100% 100%;
    /* background-position: -300px -163px;   */
  }
}

@media only screen and (max-width: 767px) {
  #success-method {
    height: 33rem;
  }
}

@media only screen and (max-width: 500px) {
  #success-method {
    background: url(../assets/images/success-method-bg-mobile.png) no-repeat;
    background-size: cover;
    background-position: 0;
  }
}
