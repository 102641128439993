#information {
  width: 100%;
  background-image: url(../assets/images/mobile-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: fit-content;
  padding: 60px 0 160px 0;
  transition: 2s;
}

#information .info-text {
  font-size: 2.25rem;
  color: #fff;
  text-align: center;
}

.cards-information-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  position: relative;
  padding-top: 50px;
}

.information-effect {
  bottom: 30px;
  position: relative;
  height: 42rem;
  right: 4rem;
}
.cards-information {
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.information {
  --information-size: 25rem;
  /* border: 2px solid transparent; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: .2s;
  gap: 20px;
  border: 5px dashed #fff;
  width: var(--information-size);
  height: var(--information-size);

  &__border {
    width: var(--information-size);
    height: var(--information-size);
    position: absolute;
    z-index: 10;
    display: block;
    border-radius: 20px;
    opacity: 0;
    transition: .04s;
    border: 5px solid #fff;
  }

  &__img {
    width: 8rem;
    height: 8rem;
  }
  
  &__text {
    opacity: 0;
    position: absolute;
    /* height: 7rem;
    width: 22rem; */
    color: #fff;
    width: 80%;
    text-align: center;
    height: 118px;
    display: flex;
    align-items: center ;
    transition: 0.5s;
  }
}
.bg-faq {
  display: flex;
  align-items: center;
  min-height: 874px;
  background-color: #fafafa;
}

.information:hover {
  /* border: 5px solid #fff; */
  border: 5px solid transparent;
  transform: scale(1.1);

  .information__img {
    opacity: 0;
  }

  .faq-title  {
    opacity: 0;
  }

  .information__border {
    opacity: 1;
  }

  .information__text {
    opacity: 1;
  }
}

.faq-title {
  text-align: center;
  padding: 1rem;
  color: #fff;
}

#faq > div {
  width: 100%;
}

.faq-body > div {
  padding: 2rem;
  height: 100%;
}

div.faq-view {
  // display: flex;
  display: none;
  flex-direction: column;
  gap: 24px;
  padding: 1rem;

  & h3 {
    color: #fff;
    text-align: start;
  }
}

.faq-view > p {
  color: #fff;
}

.active .faq-view {
  display: flex;
}

div.faq-item {
  position: relative;
  padding-top: 24px;
  // padding-bottom: 24px;
  text-align: start;
  cursor: pointer;

  & > p {
    width: 90%;
    color: #fff;
  }

  &::after {
    content: "";
    display: block;
    margin-top: 24px;
    width: 100%;
  }

  &:nth-last-child(n + 2)::after {
    height: 2px;
    background-color: #d9d9d9;
  }

  & > span.arrow {
    display: block;
    width: 15px;
    height: 20px;
    position: absolute;
    top: 27px;
    right: 0;
    margin-left: 30px;
    background-image: url("../assets/svg/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.faq-list.active .faq-item > p {
  font-weight: bold;
  color: #fff;
}

.faq-list.active .faq-item > span.arrow {
  background-image: url("../assets/svg/arrow-top.svg");
}

@media only screen and (max-width: 1500px) {
/*   #information {
    min-height: 70rem;
  } */
}

@media only screen and (max-width: 1023px) {
  .cards-information-container {
    flex-wrap: wrap;
    gap: 4rem;
  }

  .cards-information {
    gap: 4rem;
  }

  .information {
    --information-size: 19.5rem;

    &__img {
      width: 3.5rem;
      height: 3.5rem;
    }

    &__text {
      font-size: 1rem;
      width: 90%;
    }
  }
  .faq-title {
    font-size: 1.325rem;
  }
}

@media only screen and (max-width: 767px) {
  #information {
    width: 100%;
    height: fit-content;
    padding-top: 30px;
  }

  .information {
    gap: 0px;
  }

  .cards-information-container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  
  .information-effect {
    bottom: 30px;
    position: relative;
    height: 42rem;
    right: 4rem;
  }
  .cards-information {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .information {
    --information-size: 15rem;
    border: 0px solid transparent;
    display: flex;

    
    &__border {
      display: none;
    }

    &__img {
      border:  2px dashed #fff;
      border-radius: 10px;
      padding: 15px;
      width: 4rem;
      height: 4rem;
    }
    
    &__text {
      opacity: 1;
      width: 90%;
      height: 100px;
      color: #fff;
      position: static;
      display: block;
      font-size: 0.8rem;
    }
  }

  .information:hover {
    border: 0px solid transparent;
    .information__img {
      border-style: solid;
      opacity: 1;
    }

    .faq-title {
      opacity: 1;
    }
  }

  .bg-faq {
    display: flex;
    align-items: center;
    min-height: 874px;
    background-color: #fafafa;
  }

  .faq-title {
    text-align: center;
    color: #fff;
    font-size: 1rem;
  }

  #faq > div {
    width: 100%;
  }

  .faq-body > div {
    padding: 2rem;
    height: 100%;
  }

  div.faq-view {
    // display: flex;
    display: none;
    flex-direction: column;
    gap: 24px;
    padding: 1rem;

    & h3 {
      color: #fff;
      text-align: start;
    }
  }

  .faq-view > p {
    color: #fff;
  }

  .active .faq-view {
    display: flex;
  }

  div.faq-item {
    position: relative;
    padding-top: 24px;
    // padding-bottom: 24px;
    text-align: start;
    cursor: pointer;

    & > p {
      width: 90%;
      color: #fff;
    }

    &::after {
      content: "";
      display: block;
      margin-top: 24px;
      width: 100%;
    }

    &:nth-last-child(n + 2)::after {
      height: 2px;
      background-color: #d9d9d9;
    }

    & > span.arrow {
      display: block;
      width: 15px;
      height: 20px;
      position: absolute;
      top: 27px;
      right: 0;
      margin-left: 30px;
      background-image: url("../assets/svg/arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .faq-list.active .faq-item > p {
    font-weight: bold;
    color: #fff;
  }

  .faq-list.active .faq-item > span.arrow {
    background-image: url("../assets/svg/arrow-top.svg");
  }
}
