#core-business {
  background: url(../assets/images/core-business-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 90vh;
  /* background-position: 0; */
}

#core-business .info-container .info-text-content {
  width: 100%;
  max-width: 578px;
}

@media only screen and (max-height: 700px) {
  #core-business {
    height: 35rem;
  }
}

@media only screen and (max-width: 1023px) {
  #core-business {
    height: 35rem;
    background-size: 100% 100%;
    /* background-position: -300px -163px;   */
  }
}

@media only screen and (max-width: 767px) {
  #core-business {
    height: 33rem;
  }
}

@media only screen and (max-width: 500px) {
  #core-business {
    background: url(../assets/images/core-business-bg-mobile.png) no-repeat;
    background-size: cover;
    background-position: 0px;
  }
}
