.logo {
  width: 16rem;
}
.text-top {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}
.top {
  &__bg {
    background-image: linear-gradient(
        0deg,
        rgba(0, 36, 71, 0.9) 0%,
        rgba(0, 36, 71, 0.9) 100%
      ),
      url(../assets/images/top-bg.jpg);
    width: 100%;
    height: 90vh;
    gap: 60px;
    background-repeat: no-repeat;
    background-size: 100% 120%;
  }

  &__title {
    width: 80%;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: normal;
  }

  &__subtitle {
    width: 53rem;
    color: #fff;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 1.9rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    height: 90%;
    justify-content: center;
  }

  &__button {
    cursor: pointer;
    max-width: 35rem;
    width: 100%;
    height: 5rem;
    color: #fff;
    font-size: 2rem;

    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #816701;
    border-radius: 1.5rem;
    border: 0 solid;
    outline: 1px solid;

    outline-color: #816701;
    outline-offset: 0px;
    text-shadow: none;
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    
    &__position {
      display: flex;
      position: relative;
      justify-content: center;
      bottom: 2.6rem;
      z-index: 4;
    }
  }
}
.top__button:hover {
  outline-color: #e6bc18;
  outline-offset: 25px;
  background-color: #e6bc18;
  outline-color: rgba(255, 255, 255, 0);
}

@media only screen and (max-height: 700px) {
  .logo {
    margin: 1rem 0rem;
    width: 22rem;
    height: 5rem;
  }
  .top {
    &__bg {
      width: 100%;
      height: 52rem;
      background-repeat: no-repeat;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
    }

    &__title {
      width: 80%;
      color: #fff;
      font-style: normal;
      line-height: normal;
      /* font-size: 3rem; */
      text-align: left;
    }

    &__subtitle {
      width: 54rem;
      color: #fff;
      font-style: normal;
      line-height: normal;
      // font-size: 1rem;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 7rem;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .logo {
    margin: 1rem;
    width: 20rem;
  }
  .text-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .info__images {
    width: 3rem !important;
    height: 3rem !important;
  }

  .top {
    &__bg {
      width: 100%;
      height: 90vh;
      background-repeat: no-repeat;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: 100% 120%;
    }

    &__title {
      width: 54rem;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 1.8rem;
      /* font-size: 3rem; */
      text-align: left;
    }

    &__subtitle {
      width: 54rem;
      color: #fff;
      font-style: normal;
      font-size: 1rem;
      font-weight: 700;
      line-height: normal;
      // font-size: 1rem;
      text-align: left;
      margin: 0 auto;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 50px;
    }

    &__button {
      font-size: 1.5rem;
      border-radius: 1rem;
      width: 28rem;
      height: 3.5rem;

      &__position {
        display: flex;
        justify-content: center;
        bottom: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .logo {
    margin: 0 0rem 1rem 0rem;
    width: 12rem;
  }
  .text-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .top {
    &__bg {
      width: 100%;
      height: 40rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: 50%;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      width: 40rem;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 2.3rem;
      text-align: left;
    }

    &__subtitle {
      width: 40rem;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-size: 1.5rem;
      text-align: left;
      margin: 0 auto;
    }

    &__text {
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 30px;
    }

    &__button {
      width: 23rem;
      height: 3.5rem;
      border-radius: 0.5rem;
    }
  }

}

@media only screen and (max-width: 767px) {
  .logo {
    margin: 0 0rem 1rem 0rem;
    width: 10rem;
  }
  .text-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .top {
    &__bg {
      width: 100%;
      height: 40rem;
      background-repeat: no-repeat;
      background-size: cover;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      width: 14rem;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 1.4rem;
      text-align: center;
    }

    &__subtitle {
      width: 14rem;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-size: 1rem;
      text-align: center;
      margin: 0 auto;
    }

    &__text {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 10px;
    }

    &__button {
      width: 14rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 0.9rem;

      &__position {
        display: flex;
        position: relative;
        justify-content: center;
        bottom: 1.3rem;
      }
    }
  }
}
