#resilience {
  background: url(../assets/images/resilience-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 90vh;
  position: relative;
}

#resilience .info-container-right {
 position: relative;
 top: -80px;
}

@media only screen and (max-height: 700px) {
  #resilience {
    height: 40rem;
  }
}

@media only screen and (max-width: 1023px) {
  #resilience {
    height: 35rem;
    background-size: 190% 190%;
    background-position: -93px -307px;
  }
}

@media only screen and (max-width: 767px) {
  #resilience {
    height: 33rem;
  }

  #resilience .info-container-right {
    top: -56px;
   }
}

@media only screen and (max-width: 500px) {
  #resilience {
    background: url(../assets/images/resilience-bg-mobile.png) no-repeat;
    background-size: cover;
  }
}
