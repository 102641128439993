#info-aboutus {
  background: url(../assets/images/info-about-us-bg.png) no-repeat;
  background-size: 100% 100%;
  /* padding-top: 50px;   */
  height: 90vh;
  position: relative;
  /* background-position: 0; */
}

.gotocontact-button {
  cursor: pointer;
  max-width: 28rem;
  width: 100%;
  height: 4rem;
  color: #fff;
  font-size: 1.8rem;

  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #816701;
  border-radius: 1.5rem;
  border: 0 solid;
  outline: 1px solid;

  outline-color: #816701;
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &__position {
    display: flex;
    position: absolute;
    z-index: 4;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -2rem;
  }
}

.gotocontact-button:hover {
  outline-color: #e6bc18;
  outline-offset: 25px;
  background-color: #e6bc18;
  outline-color: rgba(255, 255, 255, 0);
}

#info-aboutus .info-container .info-text-content {
  font-weight: bold;
}

@media only screen and (max-height: 700px) {
  #info-aboutus {
    height: 35rem;
  }
}

@media only screen and (max-width: 1400px) {
  #info-aboutus .info-container .info-text {
    font-size: 1.8rem;
    width: 100%;
    max-width: 690px;
    margin-bottom: 30px;
  }

  .mob-button {
    font-size: 1.5rem;
    border-radius: 1rem;
    width: 28rem;
    height: 3.5rem;
  }
}

@media only screen and (max-width: 1023px) {
  #info-aboutus {
    height: 35rem;
    background-size: 1440px 800px;
    background-position: -300px -163px;
  }

  .gotocontact-button {
    width: 23rem;
    height: 3.5rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    margin-top: 3.2rem;

    &__position {
      top: -5rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  #info-aboutus {
    height: 33rem;
  }

  .gotocontact-button {
    width: 14rem;
    height: 2.5rem;
    font-size: 0.9rem;

    &__position {
      top: -4.5rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  #info-aboutus {
    background: url(../assets/images/info-about-us-bg-mobile.png) no-repeat;
    background-size: cover;
    background-position: 0;
  }
}
