#mobile {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(0, 36, 71, 0.9) 0%,
      rgba(0, 36, 71, 0.9) 100%
    ),
    url(../assets/images/bgmobile.jpg);
  background-size: cover;
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &__title {
    color: #fff;
    text-align: left;
    padding: 40px;
    width: 935px;
    font-size: 2.25rem;
  }

  &__img {
    width: 26rem;
    height: 25rem;
  }

  &__container {
    margin-top: 1rem;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    & a {
      > img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  &__btn {
    width: 5rem;
    height: 5rem;
  }
  &__btn.prev {
    background: url(../assets/svg/arow-left.svg) no-repeat;
    background-size: contain;
  }

  &__btn.next {
    background: url(../assets/svg/arow-right.svg) no-repeat;
    background-size: contain;
  }
}

.mob-button {
  cursor: pointer;
  max-width: 35rem;
  width: 100%;
  height: 5rem;
  color: #fff;
  font-size: 2.5rem;

  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #816701;
  border-radius: 1.5rem;
  border: 0 solid;
  outline: 1px solid;

  outline-color: #816701;
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &__position {
    display: flex;
    position: absolute;
    z-index: 4;
    justify-content: center;
    top: 93.7%;
  }
}

#body {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@media only screen and (max-width: 1439px) {
  #mobile__img {
    width: 17rem;
    height: 16rem;
  }
  .mob-button {
    width: 19rem;
    height: 3rem;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;

    &__position {
      top: 96.3%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #mobile {
    background-size: cover;
    width: 100%;
    height: 30rem;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 7rem;

    &__title {
      color: #fff;
      text-align: left;
      width: 90%;
      font-size: 1.5rem;
      padding: 0;
    }

    &__img {
      width: 15rem;
      height: 10rem;
    }

    &__container {
      margin-top: 1rem;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      gap: 3rem;

      & a {
        > img {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }

  .mob-button {
    width: 13rem;
    height: 2.5rem;
    border-radius: 0.8rem;
    font-size: 1rem;

    &__position {
      top: 95.8%;
    }
  }
  .mob-button:hover {
    background: #6e5801;
  }

  #body {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  #mobile {
    background-size: cover;
    width: 100%;
    height: 30rem;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0;

    &__title {
      color: #fff;
      text-align: left;
      width: 90%;
      font-size: 1rem;
    }

    &__img {
      width: 15rem;
      height: 10rem;
    }

    &__container {
      margin-top: 1rem;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      gap: 3rem;

      & a {
        > img {
          width: 5rem;
          height: 5rem;
        }
      }
    }
    &__btn {
      width: 5rem;
      height: 5rem;
    }
    &__btn.prev {
      background: url(../assets/svg/arow-left.svg) no-repeat;
      background-size: contain;
    }

    &__btn.next {
      background: url(../assets/svg/arow-right.svg) no-repeat;
      background-size: contain;
    }
  }

  .mob-button {
    width: 13rem;
    border-radius: 0.8rem;
    height: 2.5rem;
    font-size: 1rem;

    &__position {
    }
  }
  .mob-button:hover {
    background: #6e5801;
  }

  #body {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}
