#pillars {
  height: 70rem;
  display: flex;
  align-items: center;
}

.pillars-wrapper {
  background: url(../assets/images/pillars-background.png) no-repeat;
  background-size: 119% 100%;
  background-position: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.pillar-circle-image {
  width: 600px;
}

.circle-info-container, .pillars-info-container {
  width: 50%;
  text-align: center;
}

.circle-info {
  display: none;
}

.pillars-title {
  text-align: center;
  line-height: 3.7rem;
  font-size: 3.5rem;
  max-width: 400px;
  color: #fff;
  margin: 0 auto;
}

.pillar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 380px;
  height: 100px;
  display: flex;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 5px;
  align-items: center;
  margin: 30px auto;
  box-shadow: 0px 0px 10px 1px #ffffff8a;
  transition: .3s;

  &__text {
    font-weight: 700;
    color: #fff;
    font-size: 1.7rem;
    line-height: 1.8rem;
    text-align: center;
  }
}

.pillar-container:hover {
  background-color: #cf421f;
}

@media only screen and (max-width: 1023px) {
  #pillars {
    height: fit-content;
    justify-content: center;
  }

  .pillar-circle-image {
    width: 500px;
  }

  .pillars-wrapper {
    flex-direction: column-reverse;
    padding: 30px;
    background: url(../assets/images/pillars-background-vertical.png) no-repeat;
    background-size: 100% 100%;
  }

  .circle-info-container, 
  .pillars-info-container  {
    margin: 20px 0;
    width: fit-content;
  }

  .circle-info-container {
    width: 100%;
  }

  .pillar-circle-image {
    width: 100%;
  }
  
  .pillars-title {
    line-height: normal;
    font-size: 3rem;
  }

  .pillar-container {
    max-width: 330px;
    height: 100px;

    &__text {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
  }
  
}

@media only screen and (max-width: 500px) { 
  .pillars-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .pillar-container {
    max-width: 100%;
    height: 80px;

  
    &__text {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

}