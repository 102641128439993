#contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#contact {
  background: url(../assets/svg/effect.svg),url(../assets/svg/effect2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100vh;
  background-position: -50rem -5rem, 145%;
}

.contact-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.container.contact-main-container {
  max-width: 1300px;
}

#logo-obrigado {
  position: absolute;
  margin: 2rem;
  max-width: 15rem;
  height: auto;
}

#obrigado-header {
  /* background: url(../assets/images/Obrigado-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-title-obrigado {
  font-size: 28px;
  margin: 0 16px;
  text-align: center;
  line-height: 44.4px;
  font-weight: 700;
  font-size: 50px;
  color: #fff;
}

.header-description-obrigado {
  margin: 0;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  font-weight: 600;
  line-height: 27px;
  margin-top: 40px;
}

.button-home {
  background-color: #b28e03;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  padding: 10px;
  border-radius: 8px;
  margin: 10px;
}

.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contatos {
  &__formulario-bg {
    border-radius: 37px;
    border: 2px solid #1b4364;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 40rem;
    justify-content: center;
    align-items: center;
    background: #003a72;
  }
}

.contact-title {
  text-align: left;
  color: #fff;
  margin: 10px;
  width: 100%;
  font-size: 2rem;
}

.contact-text {
  color: var(--color-white-bg);
  font-size: 2rem;
}
textarea {
  font-weight: 700 !important;
  font-size: 19px !important;
}
.form-box {
  font-style: normal;
  font-weight: 500;
  color: #fff;

  & input,
  select,
  textarea {
    min-height: 40px;
    width: 100%;
    border: 1px solid #575757;
    border-radius: 8px;

    color: #000;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    text-align: start;
    resize: none;
    padding: 0.5rem;
  }

  & select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    background-image: url(../assets/svg/select-arrow.svg);
    background-color: var(--color-white-bg);
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }
  & .form-error-message {
    min-height: 40px;
    width: 100%;
    border: 1px solid red;
    border-radius: 8px;

    color: #000;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    text-align: start;

    resize: none;
    padding: 0.5rem;
  }
}

// begin form error

.error-input {
  display: none;
  color: red;

  &.active {
    display: inline;
  }
}
// end form error

.required-obs {
  color: red;
}

.form-contact {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;

  & > div {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 22px;
  }
}

.button-send {
  height: 40px;
  background: #b28e03;
  border-radius: 8px;
  border: 1px solid #575757;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

// Para o caso de a tela estar curta a o width ainda grande
@media only screen and (max-height: 700px) {
  #contact {
    height: 60rem;
  }
  .contatos__formulario-bg {
    width: 90%;
    height: 50rem;
  }
  .contact-title {
    margin: 1rem;
    margin-bottom: 0;
    font-size: 2rem;
    padding: 0;
  }
  .contact-text {
    margin: 1rem;
    font-size: 1.5rem;
  }

  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-send {
    margin: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  #contact {
    background-position: -50rem -5rem, 50rem 0rem;
  }
  .contact-title {
    font-size: 1.9rem;
  }
  .contact-text {
    font-size: 1.9rem;
  }
  
}


@media screen and (max-width: 1023px) {
  #contact {
    height: 60rem;
    background-position: -50rem -5rem, 40rem 0rem;
  }

  .contact-main-container {
    display: block;
  }
  .contatos__formulario-bg {
    width: 90%;
    height: 44rem;
  }
  .contact-title {
    margin: 100px auto;
    /* width: 5%; */
    padding: 0 30px;
  }
  .contact-text {
    margin: 1rem;
    font-size: 1.5rem;
  }

  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-send {
    margin: 1rem;
  }
}

@media screen and (max-width: 767px) {
  #contact {
    background-position: -50rem -5rem, 20rem 0rem;
  }

  .contatos__formulario-bg {
    width: 90%;
    height: 35rem;
  }
  .contact-title {
    font-size: 1rem;
    text-align: center;
    padding: 0 20px;
    margin: 65px auto;
  }
  .contact-text {
    margin: 0.5rem;
    font-size: 1rem;
    text-align: center;
  }

  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-send {
    margin: 1rem;
  }
}
